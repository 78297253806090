@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Inter';
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #35363a;
  font-size: 12px;
  line-height: 14.52px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tblstatus {
  min-width: 6em;
  height: 20px;
  padding: 0rem 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  outline: none;
  text-align: center;
}
.status-select {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 6px;
  padding: 8px;
}
@layer base {
  :root {
    --accent: #fa9b31;
    --light-accent: #fffae8;
    --main-red: #e93837;
  }
}

/* Login */
@media screen and (max-width: 1300px) {
  .login-main {
    width: 1000px;
    height: 570px !important;
  }
  .login-r {
    margin-top: 0 !important;
    height: 500px !important;
  }
}

.rectangle {
  color: #35363a;
  /*display: flex;*/
  width: fit-content !important;
  height: fit-content !important;
  padding: 5px;
  margin: 0 5px;
  /* pointer-events: none; */
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #ececec;
  background: #fff;
  cursor: auto;
}

.custom-focused-input input:focus {
  border-color: #de2128 !important;
  width: 100%;
}

/*.jodit-container .jodit-workplace .jodit-wysiwyg {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.answer-placeholder:empty:not(:focus):before {
  content: attr(data-text);
  color: #999999;
}*/

.custom_jodit,
.custom_jodit--hidden {
  width: 100% !important;
}

.custom_jodit .jodit-container,
.custom_jodit--hidden .jodit-container {
  border: none !important;
}

.custom_jodit .jodit-wysiwyg,
.custom_jodit--wrapper .jodit-container,
.custom_jodit .jodit-workplace,
.custom_jodit--hidden .jodit-wysiwyg,
.custom_jodit--hidden .jodit-workplace {
  min-height: 45px !important;
  min-width: 100% !important;
  border: none !important;
  background-color: transparent !important;
}

.custom_jodit .jodit-status-bar,
.custom_jodit--hidden .jodit-status-bar {
  display: none;
}

.custom_jodit--wrapper .jodit-react-container,
.custom_jodit--hidden .jodit-react-container {
  flex: 1;
}

.custom_jodit--wrapper .jodit-container:not(.jodit_inline) {
  border: none !important;
  background-color: transparent !important;
}

.custom_jodit--hidden .jodit-toolbar__box {
  display: none;
}

.wrapper {
  border: 1px solid #d9d9d9;
  margin-top: -50px;
  background-color: #fff;
  padding: 30px;
}

.jodit-popup {
  left: 45% !important;
  top: 45% !important;
}
